<template>
  <div class="workplatform" id="workplatform">
    <div class="workplatform-wrap">
      <div class="top notice"><div class="notice-content"><i style="padding-right: 5px" class="iconfont icongantanhao"></i>通知公告: 新版本功能上线啦！点击 查看</div><span class="loginOut" @click="loginOut">【退出】</span></div>
      <div class="main-platform">
        <div class="left">
          <div class="work-tab">
            <div @click="setTabItem(0)" :class="{'tab-item': true, 'tab-item-active': tab_active == 0}"> <span class="a">待接待</span> </div>
            <div @click="setTabItem(1)" :class="{'tab-item': true, 'tab-item-active': tab_active == 1}"><span class="a">接待中</span></div>
            <div @click="setTabItem(2)" :class="{'tab-item': true, 'tab-item-active': tab_active == 2}"><span class="a">已完成</span></div>
          </div>
          <div class="client-group" v-if="tab_active == 0 || tab_active == 1">
            <div class="client-item" @click="selectClient(item)" v-for="(item, index) in clientList" :key="index">
              <div class="client-item-left">
                <img class="client-avatar" :src="item.avatar" alt="">
                <div class="client-blade" v-if="item.blade">!</div>
              </div>
              <div class="client-item-right">
                <div class="client-item-line">
                  <div class="client-nickname">{{item.nick_name}}</div>
                  <div class="client-time">{{item.time}}</div>
                </div>
                <div class="client-message">{{item.chat_content}}</div>
              </div>
            </div>
            <div class="client-item client-empty" v-if="clientList.length <= 0">暂无用户</div>
          </div>
          <div class="client-group" v-else>
            <div class="client-item" @click="selectClient(item)" v-for="(item, index) in completeCLientList" :key="index">
              <div class="client-item-left">
                <img class="client-avatar" :src="item.avatar" alt="">
                <div class="client-blade" v-if="item.blade">!</div>
              </div>
              <div class="client-item-right">
                <div class="client-item-line">
                  <div class="client-nickname">{{item.nick_name}}</div>
                  <div class="client-time">{{item.time}}</div>
                </div>
                <div class="client-message">{{item.chat_content}}</div>
              </div>
            </div>
            <div class="client-item client-empty" v-if="clientList.length <= 0">暂无用户</div>
          </div>
        </div>
        <div class="right">
          <div class="talk-area" v-if="selectClientInfo.user_id">
            <div class="talk-user-title">与【{{selectClientInfo.nick_name}}】的对话</div>
            <div class="talk-msg-area" id="talk-msg-area">
              <div class="" v-for="(item, index) in clientMsgList" :key="index" >
                  <div class="talk-msg-msgitem talk-msg-left" v-if="!item.is_me">
                    <div class="talk-msg-msgleft"><img :src="item.avatar" alt=""></div>
                    <div class="talk-msg-msgright">
                        <div class="talk-msg-title"><span class="talk-msg-kefuname">{{item.nick_name}}</span><span class="talk-msg-time">{{item.time}}</span></div>
                        <div class="talk-msg-msgcon" v-html="item.chat_content"></div>
                    </div>
                  </div>

                  <div class="talk-msg-msgitem talk-msg-right" v-else>
                    <div class="talk-msg-msgright">
                      <div class="talk-msg-title"><span class="talk-msg-time">{{item.time}}</span><span class="talk-msg-kefuname">{{item.server_nick_name}}</span></div>
                      <div class="talk-msg-msgcon" v-html="item.chat_content"></div>
                    </div>
                    <div class="talk-msg-msgleft"><img :src="item.server_avatar" alt=""></div>
                  </div>
              </div>

              <!-- <div class="yy-service-emoji" v-if="showEmoji">
                <img @click="setMersgContentEmoji(item)" v-for="(item, index) in yyserviceEmojiList" :key="index" :src="item" />
              </div> -->
            </div>
            <div class="talk-tool-area">
              <div class="talk-tool-line">
                <i class="iconfont yy-iconfont iconemotion-happy-line showEmojiBox" @click="showEmojiBox"></i>
                <i class="iconfont yy-iconfont iconscissors-2-line"></i>
                <i class="iconfont yy-iconfont iconfile-upload-line"></i>
                <i class="iconfont yy-iconfont icondownload-cloud-2-line"></i>
                <i class="iconfont yy-iconfont iconstar-line"></i>
              </div>
              <div class="yy-service-emoji" v-if="showEmoji">
                <img @click="setMersgContentEmoji(item)" v-for="(item, index) in yyserviceEmojiList" :key="index" :src="item" />
              </div>
            </div>
            <div class="talk-send-aera">
              <div contenteditable="true" v-html="messageContent" class="textarea" name="" id="server-send-msg" placeholder="请输入您想咨询的问题"></div>
              <div class="talk-send-btn">
                <button @click="sendChatMessage" class="btn btn-mid btn-primary" type="button">发送</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { utils, yyservice_emoji } from '@/utils/utils'
export default {
  name: 'workplatform',
  props: {
    msg: String
  },
  data () {
    return {
      wspayload: {
        type: 'serve',
        server_id: '',
        server_nick_name: '',
        server_avatar: '',
        account_id: '',
      },
      selectClientInfo: {},
      serverInfo: {},
      messageContent: '',
      canSendMsg: false,
      yyserviceEmojiList: [],
      showEmoji: false,
      tab_active: 0,
      completeCLientList: []
    }
  },
  setup() {
    let ws = ref({})
    let clientMsgList = ref([])
    let clientList = ref([])
    onMounted(function() {})
    return {
      ws,
      clientList,
      clientMsgList,
    }
  },
  mounted() {
    // 初始化serverInfo
    let serverInfo = localStorage.getItem('serverInfo') || false, t = this
    if (!serverInfo) {
      this.$route.push('/login')
    }
    serverInfo = JSON.parse(serverInfo)
    this.serverInfo = serverInfo
    var wspayload = {}
    wspayload = Object.assign(wspayload, serverInfo)
    wspayload.server_id = wspayload.id
    this.wspayload = wspayload
    setTimeout(function() {
      t.connectWs()
    }, 10)
    this.setEmoji()
    this.getClientList()
    // 监听回车按键
    window.addEventListener("keydown", function (event) {
      if (event.defaultPrevented) {
        return; // Should do nothing if the default action has been cancelled
      }

      var handled = false;
      console.log(event.keyCode)
      if (event.key == 'Enter' || event.keyCode == 13) {
        t.sendChatMessage()
      }

      if (handled) {
        // Suppress "double action" if event handled
        event.preventDefault();
      }
    }, true);
  },
  methods: {
    setEmoji: function() {
      this.yyserviceEmojiList = yyservice_emoji
    },
    showEmojiBox: function() {
      this.showEmoji = !this.showEmoji
    },
    setMersgContentEmoji: function(item) {
      let msgObj = document.getElementById('server-send-msg'), msg = msgObj.innerHTML
      msg += '<img class="yyservice-emoji-img" src="'+ item +'" />'
      msgObj.innerHTML = msg
    },
    setTabItem(item) {
      this.tab_active = Number(item)
      this.completeCLientList = []
      if (Number(item) == 2) {
        this.getClientList()
      } else {
        // this.
      }
    },
    init: function() {
      var params = {
        server_account: 'yuyuan004',
        server_password: '123'
      }, wspayload = this.wspayload, t = this, serverInfo = t.serverInfo
      utils.post('serverLogin', params).then(res => {
        serverInfo = Object.assign(serverInfo, res)
        wspayload = Object.assign(wspayload, res)
        wspayload.server_id = wspayload.id
        this.wspayload = wspayload

        let commonAccount = {
          app_key: wspayload.app_key,
          account_id: wspayload.account_id,
          server_id: wspayload.server_id
        }

        localStorage.setItem('commonAccount', JSON.stringify(commonAccount))
        setTimeout(function() {
          t.serverInfo = serverInfo
          t.connectWs()
        }, 10)
      })
    },
    getClientList: function() {
      let t = this, params = {
        status: this.tab_active
      }
      utils.get('chatStatusList', params).then(res => {
          t.completeCLientList = res
      })
    },
    connectWs: function() {
      let wsUrl = 'wss://api.yyservice.nbyuyuan.net/ws/'
      this.ws = new WebSocket(wsUrl)
      var wspayload = this.wspayload, t = this
      console.log(3434)
      this.ws.onopen = function() {
        wspayload.type = 'server'
        console.log(wspayload)
        t.ws.send(JSON.stringify(wspayload))
      }

      this.ws.onmessage = function(evt) {
        t.formattWebsocketMessgae(evt)
      }
    },
    formattWebsocketMessgae(evt) {
      var payload = evt.data
      payload = payload ? JSON.parse(payload) : {};
      payload = payload.data
      var msgType = payload.type
      switch (msgType) {
        case 'server': console.log(payload); break;
        case 'user': this.newClinetAdd(payload); break;
        case 'message': this.newClientMessage(payload); break;
      }
    },
    // 发送消息
    sendChatMessage()
    {
      let t = this, msg = document.getElementById('server-send-msg'), wspayload = t.wspayload
      if (!msg.innerHTML) {
        return
      }
      wspayload.chat_content = msg.innerHTML
      var presendMsg = t.createSendMsg(wspayload)
      this.ws.send(JSON.stringify(presendMsg))
      msg.innerHTML = ''
      presendMsg.is_me = true
      presendMsg.time = utils.formatterTimeString('hh:mm:ss', new Date())
      this.clientMsgList.push(presendMsg)
      setTimeout(function() {
        t.showEmoji = false
        utils.scrollToMsgBoxBottom('talk-msg-area')
      }, 10)
    },
    createSendMsg(payload) {
      var selectInfo = this.selectClientInfo
      return {
        chat_status_id: selectInfo.chat_status_id,
        account_id: payload.account_id,
        app_key: payload.app_key,
        chat_content: payload.chat_content,
        server_nick_name: payload.server_nick_name,
        server_avatar: payload.server_avatar,
        server_id: payload.server_id,
        user_id: selectInfo.user_id,
        avatar: selectInfo.avatar,
        nick_name: selectInfo.nick_name,
        status: payload.status,
        type: 'message',
        receiver_id: selectInfo.user_id,
        sender_id: payload.server_id
      }
    },
    newClinetAdd(payload) {
      console.log(payload)
      var t = this, clientList = t.clientList, cl = clientList.length
      var d = JSON.parse(JSON.stringify(payload))
      d.blade = true
      if (cl == 0) {
        console.log(d)
        clientList.push(d)
      } else {
        for (var i = cl; i --;) {
          if (clientList[i]['user_id'] == d.user_id) {
            clientList[i] = d
          }
        }
      }
      this.clientList = clientList
      console.log(clientList)
      console.log('%c 新用户连接成功:' + d.nick_name, 'color: red')
    },
    selectClient(item) {
      var t = this, clientList = t.clientList, cl = clientList.length, selectClientInfo = this.selectClientInfo
      if (selectClientInfo.user_id == item.user_id) {
        return
      }
      for (var i = cl; i --;) {
        if (clientList[i]['user_id'] == item.user_id) {
          clientList[i]['blade'] = false
        }
      }
      this.selectClientInfo = item
      this.clientList = clientList
      this.getclientHistoryMessage(item)
    },
    getclientHistoryMessage(item) {
      let t = this, clientMsg = t.clientMsgList, page = this.page, params = {
        user_id: this.wspayload.server_id,
        receiver_id: item.user_id,
        page: page,
        limit: 20,
        is_user: 0
      }
      utils.get('chatRecordList', params).then(res => {
        clientMsg = clientMsg.concat(res.list)
        t.clientMsgList = clientMsg
      })
    },
    newClientMessage(payload) {
      console.log(payload)
      var t = this, clientMsgList = t.clientMsgList, serverInfo = t.serverInfo
      if (serverInfo.id == payload.user_id) {
        payload.is_me = true
      } else {
        payload.is_me = false
      }
      clientMsgList.push(payload)
      setTimeout(function() {
        utils.scrollToMsgBoxBottom('talk-msg-area')
      }, 10)
      // var d = JSON.parse(JSON.stringify(payload))
    },
    loginOut() {
      var t = this
      utils.showModalBox('您确定要退出工作台吗?', () => {
        localStorage.removeItem('serverInfo')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('commonAccount')
          t.$router.push({path: '/login'})
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#workplatform{
  z-index: 20;
  width: 1000px;
  height: 80%;
  background-color: #f0f0f0;
  box-shadow: 0 0 6px 2px #e5e5e5;

  .workplatform-wrap{
    width: 1000px;
    height: 100%;
    .top{
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #fafafa;
      color: #888;
      font-size: 14px;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;
      .loginOut{
        cursor: pointer;
      }
    }
    .main-platform{
      display: flex;
      justify-content: flex-start;
      height: calc(100% - 40px);
      .left{
        width: 300px;
        height: 100%;
        box-sizing: border-box;
        border-right: 1px solid #d5d5d5;
        background-color: #fff;

        .work-tab{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 10px 0;
          font-size: 14px;
          .tab-item{
            width: calc(100% / 3);
            text-align: center;
            border-bottom: 1px solid #d5d5d5;
            padding-bottom: 6px;
            cursor: pointer;
            & > span {
              display: inline-block;
              width: 100%;
              border-right: 1px solid #d5d5d5;
            }
          }
          .tab-item:last-child & > span{
            border-right: none
          }
          .tab-item-active {
            border-bottom: 2px solid #000
          }
        }
        .client-group{
          width: 100%;
          height: 100%;
          .client-empty{
            justify-content: center !important;
            font-size: 14px;
            color: #888;
            padding-top: 30px !important;
          }
          .client-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            box-sizing: border-box;
            padding: 10px 0 0 10px;
            padding-right: 0;
            cursor: pointer;
            .client-item-left{
              position: relative;
              width: 40px;
              height: 40px;
              img {
                width: 100%;
              }
              .client-blade{
                position: absolute;
                top: -5px;
                right: -5px;
                width: 15px;
                height: 15px;
                background-color: red;
                color: #fff;
                font-weight: bold;
                border-radius: 50%;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
              }
            }
            .client-item-right{
              width: calc(100% - 60px);
              font-size: 14px;
              border-bottom: 1px solid #f0f0f0;
              position: relative;
              .client-item-line{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 10px;
                .client-nickname{
                  color: #333;
                  width: 120px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-all;
                  box-sizing: border-box;
                }
                .client-time{
                  color: #a2a2a2;
                }
              }
              .client-message{
                width: 100%;
                padding: 15px 0 10px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                box-sizing: border-box;
              }
            }
          }
        }
      }
      .right {
        width: calc(100% - 300px);
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
        .talk-area{
          height: 100%;
        }
        .talk-user-title{
          padding: 7px 0;
          font-size: 16px;
          text-align: center;
          box-sizing: border-box;
          border-bottom: 1px solid #d5d5d5;
        }
        .talk-msg-area{
          width: 100%;
          height: 420px;
          border-bottom: 1px solid #d5d5d5;
          box-sizing: border-box;
          overflow: hidden;
          overflow-y: auto;
          position: relative;
          &::-webkit-scrollbar{
            width:5px;
            height:5px;
            /**/
          }
          &::-webkit-scrollbar-track{
            background: rgb(239, 239, 239);
            border-radius:2px;
          }
          &::-webkit-scrollbar-thumb{
            background: #bfbfbf;
            border-radius:10px;
          }
          &::-webkit-scrollbar-thumb:hover{
            background: #333;
          }
          &::-webkit-scrollbar-corner{
            background: #179a16;
          }
          .talk-msg-msgitem{
            width: 100%;
            padding: 20px 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            .talk-msg-msgleft{
              width: 40px;
              height: 40px;
              margin-right: 10px;
              & > img {
                width: 100%;
              }
            }
            .talk-msg-msgright{
              width: calc(100% - 100px);
              .talk-msg-title{
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 10px;
                font-size: 14px;
                .talk-msg-kefuname{
                  font-size: 14px;
                  margin-right: 10px;
                }
                .talk-msg-time{
                  color: #a2a2a2;
                }
              }
              .talk-msg-msgcon{
                word-break: break-all;
                padding: 10px 10px;
                box-sizing: border-box;
                background-color: rgb(239, 243, 246);
                border-radius: 10px;
                font-size: 14px;
                display: inline-block;
                min-width: 100px;
                ::v-deep .yyservice-emoji-img{
                  width: 36px;
                }
              }
            }
          }
          .talk-msg-right{
            justify-content: flex-end;
            text-align: right;
            .talk-msg-title{
              justify-content: flex-end !important;
            }
            .talk-msg-msgleft{
              margin: 0;
              margin-left: 10px;
            }
            .talk-msg-msgcon{
              text-align: right;
            }
            .talk-msg-kefuname{
              margin: 0;
              margin-left: 10px;
            }
          }
        }
        .talk-tool-area{
          border-bottom: 1px solid #d5d5d5;
          position: relative;
          .talk-tool-line{
            padding: 10px 10px;
          }
          i {
            font-size: 25px;
            margin: 0 10px;
            color: #888;
            cursor: pointer;
          }
          .yy-service-emoji{
            position: absolute;
            top: -157px;
            left: 0;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            border-top: 1px solid #f0f0f0;
            background-color: rgba(255,255,255,.8);
            z-index: 20;
            img{
              width: 36px;
              margin: 2px;
              cursor: pointer;
            }
          }
        }
        .talk-send-aera{
          height: calc(100% - 547px);
          box-sizing: border-box;
          .textarea {
            height: 100%;
            padding: 10px;
            box-sizing: border-box;
            outline: none;
            font-size: 14px;
            ::v-deep .yyservice-emoji-img {
              width: 36px;
            }
            .talk-placeholder{
              color: #a2a2a2;
            }

          }
        }
        .talk-send-btn {
          height: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          
          button {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
